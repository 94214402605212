.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 1em;
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: auto;
  min-width: 320px;
  max-width: 620px;
}

.app-footer {
  color: grey;
  height: 2rem;
}

.input {
  border-radius: 24px;
  padding: 12px 12px;
  min-height: 128px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.output {
  word-wrap: break-word;
}

.switch-button {
  border-radius: 30px;
  //overflow: hidden;
  width: 7em;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  //color: #155FFF;
  position: relative;
  padding-right: 7em;
  position: relative;

  &:before {
    content: "elie";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(7em);
      transition: transform 300ms ease;
    }

    & + .switch-button-label {
      position: relative;
      padding: 15px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        border: 2px white solid;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}